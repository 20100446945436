import React, { useState } from "react";
import styled from "styled-components";
import Widget, { Color, Font } from "./Widget";
import { WebAsset, QrCode } from "styled-icons/material";
import * as Survey from "../Contexts/SurveyContext";
import * as Ant from "antd";
import { FormatListBulleted } from "styled-icons/material-rounded";

const Items = [
  { title: "網頁收集", icon: WebAsset, method: "website" },
  { title: "ＱＲCode", icon: QrCode, method: "qrcode" },
];
const key = "loading";

// 收集方式左側 Menu
function SurveyDetailSideMenuCollect(props) {
  const [creating, setCreating] = useState(false);

  // 新增收集方式
  async function createCollection(type) {
    // if (props.collectionList.filter(c => c.method === type).length !== 0) {
    //   let foundIdx = props.collectionList.findIndex(l => l.method === type);
    //   props.setEditingIdx(foundIdx);
    //   return;
    // }
    setCreating(true);
    let mappingType = type === "website" ? 1 : 2;
    const hide = Ant.message.loading("創建中...", 0);
    try {
      let _snapshotCollectionListLength;
      if (props.collectionList.length) {
        _snapshotCollectionListLength = mappingType - 1;
      } else {
        _snapshotCollectionListLength = props.collectionList.length;
      }
      if (!props.collectionList.find(e => e.method === mappingType)) {
        await Survey.Actions.createCollection(props.survey.id, type);
        props.setUpdater(props.updater + 1);
        props.setEditingIdx(_snapshotCollectionListLength);
      } else {
        Ant.message.warning({ content: "已存在!", key });
      }
    } catch (ex) {
      // console.warn(ex);
    }
    hide();
    setCreating(false);
  }

  if (!props.collectionList) {
    //Ant.message.loading({ content: "載入中...", key });
  }

  return (
    <>
      {props && props.collectionList && (
        <Wrapper
          disable={
            props.editingIdx !== null ||
            props.survey.audit ||
            props.collectionList.length > 1 ||
            !props.canEdit
          }
        >
          <Widget.FlexRow
            style={{
              padding: "12px 20px",
              borderLeft: `4px solid ${Color.Purple}`,
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            }}
          >
            <Font.Body>收集方式</Font.Body>
          </Widget.FlexRow>
          {/* 收集方式左側呈現 */}
          {Items.map((item, idx) => (
            <Widget.FlexRow
              key={idx}
              style={{
                padding: "12px 24px",
                cursor: "pointer",
              }}
              onClick={() => !creating && createCollection(item.method)}
            >
              <item.icon className="logic-menu-icon" />
              <Font.Body style={{ color: "rgba(0,0,0,0.65)" }}>
                {item.title}
              </Font.Body>
            </Widget.FlexRow>
          ))}
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fdfcfc;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

  ${props =>
    props.disable && "cursor: not-allowed; & > * { pointer-events: none; }"}

  .logic-menu-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

export default SurveyDetailSideMenuCollect;
