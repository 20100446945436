import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Ant from "antd";
import _ from "lodash";

import Widget, { Color, Font, FontSize } from "./Widget";
import * as Icon from "./Icon";
import * as Survey from "../Contexts/SurveyContext";
import { catText } from "../Utils/RichTextUtils";
import LimitModal from "./Limit/LimitModal";
import * as SvgIcon from "./SvgIcon";
import { ExclamationCircleFilled } from "@ant-design/icons";

const QRCode = require("qrcode.react");

const FieldLabelWidth = 135;

const ValidField = {
  101: "cht",
  102: "hn",
  103: "md",
  104: "tel",
  105: "tel-2",
  106: "phone",
  107: "email",
  108: "secondary-email",
  109: "facebook-id",
  110: "serial-number",
  111: "device-id",
  202: "ex-1",
  203: "ex-2",
};

const MultiProvideType = {
  overwrite: "覆蓋舊資料",
  "first-only": "僅寫入第一筆",
  repeat: "重複紀錄",
};

const cnNums = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
class QrCodeSection extends React.Component {
  render() {
    return (
      <div style={{ marginBottom: 20 }}>
        <Widget.FlexRow style={{ alignItems: "flex-start" }}>
          <Font.Body
            style={{
              width: 80,
              marginLeft: 72,
              paddingRight: 16,
              color: Color.GreyBlack,
              textAlign: "right",
            }}
          >
            QR Code
          </Font.Body>
          <div
            className="qrcode-container"
            style={{
              height: 136,
              width: 136,
              padding: 8,
              border: "1px solid #979797",
              display: "flex",
            }}
          >
            <QRCode
              style={{ height: 118, width: 118 }}
              value={this.props.url}
            />
            <QRCode
              id="qrcode2048"
              size={2048}
              style={{ display: "none" }}
              value={this.props.url}
            />
          </div>
          <DownloadBtn style={{ marginLeft: 16 }} ref={this._onRef}>
            下載
          </DownloadBtn>
        </Widget.FlexRow>
      </div>
    );
  }

  _onRef = ref => {
    if (!ref) {
      return;
    }
    // let canvas = document.querySelector(
    //   ".qrcode-container > canvas.qrcode-download"
    // );
    // ref.href = canvas.toDataURL("image/png"); //轉換圖片為dataURL
    // ref.download = "qrcode.png";
    let canvas = document.createElement("canvas"); //建立canvas元素
    canvas.width = 2048;
    canvas.height = 2048;
    let qrcode = document.getElementById("qrcode2048");
    canvas.getContext("2d").drawImage(qrcode, 0, 0, 2048, 2048);
    ref.href = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    ref.download = "qrcode.png";
  };
}

function CollectionForm({ style = {}, ...props }) {
  const [values, setValues] = useState({
    upperBound: props.collection.upperBound || "",
    loginType: props.collection.loginType || "anonymous",
    validateField: ValidField[props.collection.validateField] || "cht",
    duplicateMechanism: props.collection.duplicateMechanism || "repeat",
    limitType: props.collection.limitType || 0,
  });
  const [limitType, setLimitType] = useState(props.collection.limitType || 0);
  const [limitSetting, setLimitSetting] = useState(null);
  const [limitVisible, setLimitVisible] = useState(false);
  //TODO limit 2 才會有資料
  useEffect(() => {
    fetchLimitData();
  }, [props.updater]);

  function copyText(elemId) {
    const textElem = document.getElementById(elemId);
    textElem.select();
    textElem.setSelectionRange(0, 99999);
    document.execCommand("copy");
    Ant.message.success("連結已複製", 1);
  }

  async function update() {
    if (!props.canEdit) {
      Ant.Modal.warning({
        title: "不可編輯",
        content: "無編輯權限!",
        okText: "確定",
        onOk: () => 0,
        icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
      });
      return;
    }

    const hide = Ant.message.loading("更新中...", 0);
    try {

      if (limitSetting !== null) {
        await Survey.Actions.updateLimitData(
          props.survey.id,
          props.collection.method,
          limitSetting
        );
      }
      await Survey.Actions.updateCollection(props.survey.id, {
        FinalUrl: props.collection.liveUrl,
        TestUrl: props.collection.testUrl,
        ProvideType: props.collection.method,
        upperBound: values.upperBound,
        loginType: values.loginType,
        validateField: values.validateField,
        duplicateMechanism: values.duplicateMechanism,
        limitType: limitType,
      });
      props.setUpdater(props.updater + 1);
      props.backToList();
    } catch (ex) {
      // console.warn(ex);
    }
    hide();
  }

  async function cancel() {
    if (Survey.Actions.shouldDeleteCollectionOnCancel()) {
      const nextList = [...props.collectionList];
      nextList.splice(nextList.length - 1, 1);
      props.setCollectionList(nextList);
      await Survey.Actions.deleteCollection(
        props.survey.id,
        props.collection.method
      );
      props.setUpdater(props.updater + 1);
    }
    props.backToList();
  }

  async function fetchLimitData() {
    const resp = await Survey.Actions.fetchLimitData(
      props.survey.id,
      props.collection.method
    );
    if (resp.code == 200) {
      setLimitSetting(resp.data);
      // if (values.upperBound && values.upperBound != "") {
      //   setLimitType(1);
      // } else if (resp.data) {
      //   setLimitType(2);
      // } else {
      //   setLimitType(0);
      // }
    }
    // console.log('resp', resp);
  }

  function onLimitChange(e) {
    if (e.target.value === 0) {
      setValues({
        ...values,
        upperBound: "",
      });
    }
    setLimitType(e.target.value);
  }

  /**
   * 2023-01-19 CR UAT#237
   * 選擇交叉選項回覆數量上限後，
   * 內容未選擇按儲存，
   * 應返回原原本選項，
   * 因並未真實設定交叉選項回覆數量限制。
   * @param {*} e
   */
  function resetLimitType() {
    setLimitType(_.get(props, "collection.limitType", 0));
  }

  const methodLabel = props.collection.method === 1 ? "網頁" : "QR Code";

  function Tag(itm, idx) {
    const question = props.survey.questions.find(
      q => q.id === `${itm}`.toLowerCase()
    );

    let value = null;
    if (question) {
      const questiobIdx = `${question.getIdxDisplay()}. `;
      const questionTitle = catText(question.content);
      value = [`${questiobIdx} ${questionTitle}`];
    }

    return (
      <div key={idx} className={`questions ${idx === 0 ? "one" : ""}`}>
        <div className="title">第{cnNums[idx + 1]}題</div>
        <Ant.Select
          mode="multiple"
          showArrow={false}
          tagRender={tagRender}
          defaultValue={value}
          value={value}
          style={{ width: "100%" }}
          disabled={true}
        />
      </div>
    );
  }

  const tagRender = props => {
    const { label, value, closable, onClose } = props;
    return <TagRenderWrapper>{label}</TagRenderWrapper>;
  };

  return (
    <Wrapper style={style}>
      <Widget.FlexRow
        style={{
          backgroundColor: Color.LightPurple,
          padding: "16px 36px 16px 32px",
        }}
      >
        <Font.Body style={{ flex: 1 }}>{`${methodLabel}收集方式`}</Font.Body>
        <Icon.CloseOutlined onClick={cancel} />
      </Widget.FlexRow>

      <div style={{ padding: 20 }}>
        <div style={{ marginBottom: 16 }}>
          <Widget.FlexRow>
            <Font.Body
              style={{
                width: 56,
                marginRight: 16,
                marginLeft: 76,
                color: Color.GreyBlack,
                textAlign: "right",
              }}
            >
              正式網址
            </Font.Body>

            {/* invisible element, since input disabled cann't be selected to copy */}
            <Ant.Input
              style={{
                position: "absolute",
                left: -999999999999999,
                opacity: 0,
              }}
              id="live-url"
              value={`${props.collection.liveUrl}`}
            />
            <Ant.Input
              id="live-url-display"
              style={{ flex: 1, marginRight: 16 }}
              value={`${props.collection.liveUrl}`}
              disabled
            />
            <Ant.Button
              type="outline"
              style={{ marginRight: 64 }}
              onClick={() => copyText("live-url")}
            >
              複製網址
            </Ant.Button>
          </Widget.FlexRow>
          <Widget.FlexRow>
            <Font.Body
              style={{ width: 56, marginRight: 16, marginLeft: 76 }}
            ></Font.Body>
            <Widget.FlexRow style={{ marginTop: 6 }}>
              <Icon.InfoCircleOutlined
                color={Color.LightGold}
                size={FontSize.head}
              />
              <Font.Body
                style={{ flex: 1, marginLeft: 10, color: Color.LightGold }}
              >
                僅在有效期間可以使用
              </Font.Body>
            </Widget.FlexRow>
          </Widget.FlexRow>
        </div>

        <div style={{ marginBottom: 20 }}>
          <Widget.FlexRow>
            <Font.Body
              style={{
                width: 56,
                marginRight: 16,
                marginLeft: 76,
                color: Color.GreyBlack,
                textAlign: "right",
              }}
            >
              測試網址
            </Font.Body>

            {/* invisible element, since input disabled cann't be selected to copy */}
            <Ant.Input
              id="test-url"
              style={{
                position: "absolute",
                left: -999999999999999,
                opacity: 0,
              }}
              value={`${props.collection.testUrl}`}
            />
            <Ant.Input
              id="test-url-display"
              style={{ flex: 1, marginRight: 16 }}
              value={`${props.collection.testUrl}`}
              disabled
            />
            <Ant.Button
              type="outline"
              style={{ marginRight: 64 }}
              onClick={() => copyText("test-url")}
            >
              複製網址
            </Ant.Button>
          </Widget.FlexRow>
          <Widget.FlexRow>
            <Font.Body
              style={{ width: 56, marginRight: 16, marginLeft: 76 }}
            ></Font.Body>
            <Widget.FlexRow style={{ marginTop: 6 }}>
              <Icon.InfoCircleOutlined
                color={Color.LightGold}
                size={FontSize.head}
              />
              <Font.Body
                style={{ flex: 1, marginLeft: 10, color: Color.LightGold }}
              >
                最多可以測試 30 人次
              </Font.Body>
            </Widget.FlexRow>
          </Widget.FlexRow>
        </div>

        {props.collection.method === 2 && (
          <QrCodeSection url={`${props.collection.liveUrl}`} />
        )}

        <div
          style={{
            height: 2,
            backgroundColor: "#bbbbcb",
            marginBottom: 20,
            marginLeft: 12,
            marginRight: 40,
          }}
        />

        <FormDetail disabled={!!props.survey.audit}>
          <div style={{ marginBottom: 16 }}>
            <Widget.FlexRow style={{ alignItems: "flex-start" }}>
              <Font.Body
                style={{
                  width: 84,
                  marginLeft: 68,
                  marginRight: 16,
                  color: Color.GreyBlack,
                  textAlign: "right",
                }}
              >
                問卷名額上限
              </Font.Body>
              <Ant.Radio.Group
                className="limit-radio-group"
                onChange={onLimitChange}
                defaultValue={limitType}
                value={limitType}
              >
                <Ant.Radio value={0} style={Styles.radio}>
                  無限制
                </Ant.Radio>
                <Ant.Radio value={1} style={Styles.radio}>
                  整份問卷回覆數量限制
                  <Ant.Tooltip title="提供整份問卷回收數量上限設定。">
                    <SvgIcon.Help
                      size={24}
                      color="#ffc53d"
                      style={{ marginLeft: 8 }}
                    />
                  </Ant.Tooltip>
                </Ant.Radio>
                {limitType === 1 ? (
                  <Ant.Input
                    style={{ width: 192, marginBottom: 9 }}
                    placeholder="最少數量為1"
                    value={values.upperBound}
                    onChange={e => {
                      const val = Number(e.target.value);
                      if (Number.isNaN(val)) return;
                      setValues({
                        ...values,
                        upperBound:
                          e.target.value === ""
                            ? ""
                            : val < 1
                            ? "1"
                            : e.target.value,
                      });
                    }}
                  />
                ) : null}
                <Ant.Radio value={2} style={Styles.radio}>
                  交叉選項回覆數量限制
                  <Ant.Tooltip title="提供 1 或 2 個題目交叉設定回收數量上限，以蒐集特定目標客群回收樣本量。">
                    <SvgIcon.Help
                      size={24}
                      color="#ffc53d"
                      style={{ marginLeft: 8 }}
                    />
                  </Ant.Tooltip>
                </Ant.Radio>
                {limitType === 2 ? (
                  <>
                    <Ant.Button
                      type="outline"
                      style={{ marginRight: 64 }}
                      onClick={() => setLimitVisible(true)}
                    >
                      編輯
                    </Ant.Button>
                    {limitSetting && limitSetting.QuestionList !== "" ? (
                      <SettingWrapper>
                        {limitSetting.QuestionList.map(Tag)}
                      </SettingWrapper>
                    ) : null}
                  </>
                ) : null}
              </Ant.Radio.Group>
            </Widget.FlexRow>
          </div>
          <div style={{ marginBottom: 16 }}>
            <Widget.FlexRow style={{ alignItems: "flex-start" }}>
              <Font.Body
                style={{
                  width: 84,
                  marginLeft: 68,
                  marginRight: 16,
                  color: Color.GreyBlack,
                  textAlign: "right",
                }}
              >
                問卷登入方式
              </Font.Body>
              <div>
                <Ant.Radio.Group
                  value={values.loginType}
                  defaultValue={"anonymous"}
                >
                  <Ant.Radio
                    style={Styles.radio}
                    value="anonymous"
                    onChange={() => {
                      setValues({
                        ...values,
                        loginType: "anonymous",
                      });
                      if (props.collection.liveUrl.includes("&p=")) {
                        props.collection.liveUrl = props.collection.liveUrl.replace(
                          "&p=",
                          ""
                        );
                      }
                      if (props.collection.testUrl.includes("&p=")) {
                        props.collection.testUrl = props.collection.testUrl.replace(
                          "&p=",
                          ""
                        );
                      }
                    }}
                  >
                    匿名，開放給任何人做填寫
                    {/* <div style={{ height: 3 }} /> */}
                  </Ant.Radio>
                  <Ant.Radio
                    style={Styles.radio}
                    value="validation"
                    onChange={() => {
                      setValues({
                        ...values,
                        loginType: "validation",
                      });
                      if (props.collection.liveUrl.includes("&p=")) {
                        props.collection.liveUrl = props.collection.liveUrl.replace(
                          "&p=",
                          ""
                        );
                      }
                      if (props.collection.testUrl.includes("&p=")) {
                        props.collection.testUrl = props.collection.testUrl.replace(
                          "&p=",
                          ""
                        );
                      }
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        margin: "unset",
                        height: 22,
                        lineHeight: "22px",
                      }}
                    >
                      登入問卷需做資格驗證，綁定此行銷活動方式內的名單
                    </span>
                  </Ant.Radio>
                  <div style={{ marginLeft: 24 }}>
                    <Widget.FlexRow style={{ marginTop: 8, marginBottom: 12 }}>
                      <Font.Body
                        style={{
                          marginRight: 10,
                          color:
                            values.loginType !== "validation"
                              ? Color.GreyBlack_25
                              : Color.GreyBlack,
                        }}
                      >
                        存取名單
                      </Font.Body>
                      <Ant.Tooltip
                        placement="bottom"
                        title={
                          <>
                            {props.collection.accessList.map((item, idx) => (
                              <div key={idx}>{item.display}</div>
                            ))}
                          </>
                        }
                      >
                        <Font.Body
                          style={{
                            color:
                              values.loginType !== "validation"
                                ? Color.GreyBlack_25
                                : Color.GreyBlack,
                          }}
                        >
                          {props.collection.accessList.length === 0
                            ? "無資料"
                            : `一共 ${props.collection.accessList.length} 筆資料`}
                        </Font.Body>
                      </Ant.Tooltip>
                    </Widget.FlexRow>
                    <Widget.FlexRow style={{ marginBottom: 12 }}>
                      <Font.Body
                        style={{
                          marginRight: 12,
                          color:
                            values.loginType !== "validation"
                              ? Color.GreyBlack_25
                              : Color.GreyBlack,
                        }}
                      >
                        驗證欄位
                      </Font.Body>
                      <Ant.Select
                        size="small"
                        style={{ width: 195 }}
                        disabled={values.loginType !== "validation"}
                        defaultValue={values.validateField}
                        value={ValidField[values.validateField]}
                        onSelect={v =>
                          setValues({ ...values, validateField: v })
                        }
                      >
                        <Ant.Select.OptGroup
                          label={<div style={{ paddingTop: 8 }}>常用欄位</div>}
                        >
                          <Ant.Select.Option value="cht">
                            CHT會員編號
                          </Ant.Select.Option>
                          <Ant.Select.Option value="hn">
                            HN號碼
                          </Ant.Select.Option>
                          <Ant.Select.Option value="md">
                            MD號碼
                          </Ant.Select.Option>
                          <Ant.Select.Option value="tel">
                            市話號碼
                          </Ant.Select.Option>
                          <Ant.Select.Option value="phone">
                            手機號碼
                          </Ant.Select.Option>
                          <Ant.Select.Option value="email">
                            主要聯絡信箱
                          </Ant.Select.Option>
                          <Ant.Select.Option value="secondary-email">
                            次要聯絡信箱
                          </Ant.Select.Option>
                        </Ant.Select.OptGroup>
                        <Ant.Select.OptGroup
                          label={
                            <div
                              style={{
                                borderTop: "1px solid #bbbbcb",
                                paddingTop: 8,
                              }}
                            >
                              備用欄位
                            </div>
                          }
                        >
                          <Ant.Select.Option value="ex-1">
                            備用欄位1
                          </Ant.Select.Option>
                          <Ant.Select.Option value="ex-2">
                            備用欄位2
                          </Ant.Select.Option>
                        </Ant.Select.OptGroup>
                      </Ant.Select>
                    </Widget.FlexRow>
                  </div>
                  {props.collection.method === 1 && (
                    <>
                      <Ant.Radio
                        style={Styles.radio}
                        value="parameter"
                        onChange={() => {
                          setValues({ ...values, loginType: "parameter" });
                          props.collection.liveUrl = `${props.collection.liveUrl}&p=`;
                          props.collection.testUrl = `${props.collection.testUrl}&p=`;
                        }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            margin: "unset",
                            height: 22,
                            lineHeight: "22px",
                          }}
                        >
                          參數傳遞，URL都會帶入名單指定欄位的傳遞值，須在其他行銷方式設定
                        </span>
                      </Ant.Radio>
                      <Font.Body
                        style={{
                          color: Color.GreyBlack_25,
                          marginTop: 8,
                          marginLeft: 24,
                        }}
                      >
                        例如：https://cht.com/app/board/o9J_kpVyy3I&ProvideType=1&p=客戶HN碼
                      </Font.Body>
                    </>
                  )}
                </Ant.Radio.Group>
              </div>
            </Widget.FlexRow>
          </div>
          <div style={{ marginBottom: 20 }}>
            <Widget.FlexRow>
              <Font.Body
                style={{
                  width: 112,
                  marginLeft: 40,
                  marginRight: 16,
                  color: Color.GreyBlack,
                  textAlign: "right",
                }}
              >
                重複填寫問卷機制
              </Font.Body>
              <Ant.Select
                style={{ width: 160 }}
                disabled={values.loginType === "anonymous"}
                defaultValue={
                  values.loginType === "anonymous"
                    ? MultiProvideType["repeat"]
                    : MultiProvideType[values.duplicateMechanism]
                }
                value={
                  values.loginType === "anonymous"
                    ? MultiProvideType["repeat"]
                    : MultiProvideType[values.duplicateMechanism]
                }
                onSelect={v => setValues({ ...values, duplicateMechanism: v })}
              >
                <Ant.Select.Option value="overwrite">
                  覆蓋舊資料
                </Ant.Select.Option>
                <Ant.Select.Option value="first-only">
                  僅寫入第一筆
                </Ant.Select.Option>
                <Ant.Select.Option value="repeat">重複紀錄</Ant.Select.Option>
              </Ant.Select>
            </Widget.FlexRow>
          </div>
        </FormDetail>
        <div
          style={{
            height: 2,
            backgroundColor: "#bbbbcb",
            marginBottom: 20,
            marginLeft: 12,
            marginRight: 20,
          }}
        />
        {!props.survey.audit && (
          <Widget.FlexRow
            style={{ justifyContent: "flex-end", marginRight: 20 }}
          >
            <Ant.Button
              type="outline"
              style={{ marginRight: 20 }}
              onClick={cancel}
            >
              取消
            </Ant.Button>
            <Ant.Button type="primary" onClick={update}>
              確認
            </Ant.Button>
          </Widget.FlexRow>
        )}
      </div>
      {limitVisible && (
        <LimitModal
          survey={props.survey}
          provideType={props.collection.method}
          setLimitVisible={setLimitVisible}
          limitVisible={limitVisible}
          limitSetting={limitSetting}
          setLimitSetting={setLimitSetting}
          values={values}
          setValues={setValues}
          onCancel={() => {
            resetLimitType();
          }}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: white;
`;

const FormDetail = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "")};

  .limit-radio-group {
    .ant-radio-wrapper {
      span:nth-child(2) {
        display: inline-flex;
        align-items: center;
      }
    }
  }
`;

const DownloadBtn = styled.a`
  margin-left: 10px;
  align-self: flex-end;
  padding: 4px 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
`;

const Styles = {
  radio: {
    display: "block",
    marginBottom: 12,
  },
};

const SettingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 9px;
  .title {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 9px;
  }

  .questions {
    width: 350px;

    &.one {
      margin-right: 12px;
    }
  }
`;

const TagRenderWrapper = styled.div`
  background-color: ${Color.MPurple_1};
  border-radius: 11px;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
`;

export default CollectionForm;
