import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import * as Ant from "antd";
import * as Icon from "../Components/Icon";
import SideMenu from "../Components/SurveyDetailSideMenu";
import Content from "../Components/SurveyDetailContent";
import STEP from "../Domain/SurveyStep";
import { Font, Color, FontSize } from "../Components/Widget";
import * as Survey from "../Contexts/SurveyContext";
import SurveyStat from "../Components/SurveyStat";
import moment from "moment";
import { InfoCircleOutlined, ExclamationCircleFilled } from "@ant-design/icons"; //wiki加入IE判斷
import * as SvgIcon from "../Components/SvgIcon";
import AlertWindow from "../Components/AlertWindow";
const queryString = require("query-string");

let windowGlobal = typeof window !== "undefined" && window;

const configs = {
  mainNavbarHeight: 66,
  subNavbarHeight: 52,
};

/**
 * 設計問券內頁主邏輯
 * @param {*} props
 * @returns
 */
const SurveyDetailPage = props => {
  let { profile, appActions } = props;

  const [updater, setUpdater] = useState(0);
  const [data, setData] = useState(null);
  const [unModelSurvey, setUnModelSurvey] = useState(null);
  const [stepData, setStepData] = useState(false);
  const [extraSurveyData, setExtraSurveyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, _setStep] = useState(STEP.CONFIG.key);
  const [editingIdx, setEditingIdx] = useState(null);
  const [editingMode, setEditingMode] = useState(false);
  const [previewMode] = useState(false);
  const [logicList, setLogicList] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const [statisticsData, setStatisticsData] = useState(null);
  const [isCreateNewPage, setIsCreateNewPage] = useState(false);
  // const [logicWarning, setLogicWarning] = useState(false);
  // const [lockInfo, setLockInfo] = useState(null);
  const [editLockUserName, setEditLockUserName] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [defaultEditTimeLimit, setDefaultEditTimeLimit] = useState(300);
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [isSticky, setIsSticky] = useState(false);

  // 編輯時間上限, default 300秒 來自api
  let editTimeLimit = useRef(defaultEditTimeLimit);
  let interval = useRef();
  let timerInterval = useRef();

  let AbortController = windowGlobal.AbortController;
  let abortCtrol;

  useEffect(() => {
    windowGlobal && windowGlobal.localStorage.getItem("token");
    let token = windowGlobal.localStorage.getItem("token");
    let urlstepck =
      props.location.state && props.location.state.hasOwnProperty("step");

    abortCtrol = new AbortController();

    if (profile && token) {
      fetchData();
    }

    if (!profile || !token) {
      appActions.logout();
      console.debug("各路工程師想要使用者回老家");
      navigate("/");
    }

    // componentwillunmount
    return () => {
      if (step === 3 && !urlstepck) {
        abortCtrol.abort();
        abortCtrol = new windowGlobal.AbortController();
      }
    };
  }, [updater, props.location, step]);
  //}, [props.location, step]);

  useEffect(() => {
    // 取得編輯時間上限秒數
    fetchEditTimeLimit();
  }, []);

  // 查詢編輯狀態，將條件設定為有更新畫面後查詢
  // 0828 : 更新 useEffect 條件，由原本每次 Render 時執行，改由有資料異動時執行
  useEffect(() => {
    if (editTimeLimit.current > 0) {
      fetchLock();
    }
  }, [
    // 資料有更新
    data,
    unModelSurvey,
    extraSurveyData,
    logicList,
    collectionList,
    statisticsData,
    stepData,
    // 畫面有更新
    updater,
    props.location,
    step,
  ]);

  useEffect(() => {
    return () => {
      // 解除編輯狀態
      fetchUnlock();
    };
  }, []);

  // 編輯時間計算
  useEffect(() => {
    // 一進來先倒數計時
    countDown();
    fetchTimer();
    return () => {
      // 離開畫面時，移除計時器
      clearInterval(interval);
      clearInterval(timerInterval);
    };
  });

  /**
   * 取得編輯時間上限
   */
  async function fetchEditTimeLimit() {
    let res = await Survey.Actions.fetchEditTimeLimit();
    if (res && res.code === "200") {
      const { data } = res;
      const { Content } = data[0];

      const parsed = parseInt(Content, 10);
      if (!isNaN(parsed)) {
        setDefaultEditTimeLimit(parsed);
      }
    }
  }

  // 刷新列表頁面
  async function fetchData() {
    let signal = abortCtrol.signal;
    setLoading(true);
    try {
      if (props.location.state && props.location.state.hasOwnProperty("step")) {
        _setStep(props.location.state.step);
        delete props.location.state.step;
      }
      const id = queryString.parse(props.location.search).id;
      let survey,
        extraSurveyData = {};
      // const surveyList = await Survey.Actions.fetchMySurveys();
      // let foundSurvey = surveyList && surveyList.find(s => s.id === id);
      // extraSurveyData.title = (foundSurvey && foundSurvey.title) || "";
      // setExtraSurveyData(extraSurveyData);

      if (step === 0) {
        let fetchSurvey = await Survey.Actions.fetchById(id, true);
        if (fetchSurvey == null) return;
        survey = fetchSurvey.survey;

        // 加入匯入成功提示
        const isFromImport = queryString.parse(props.location.search).import;
        if (isFromImport === "1") {
          Ant.message.success("已成功匯入問卷");
        }

        // 將自訂顏色放redux，提供ColorPalette Component Render用
        appActions.setTheme({
          SurveyId: survey.id,
          ExtendColorPalette: survey._data._themeProps.ExtendColorPalette,
        });

        let logicList = await Survey.Actions.fetchLogicList(id, survey);
        setData(survey);
        setUnModelSurvey(fetchSurvey.unModelSurvey);
        extraSurveyData.title = (survey && survey.title) || "";
        setExtraSurveyData(extraSurveyData);
        setLogicList(logicList);
      } else if (step === 1) {
        setStepData(true);
        // survey = await Survey.Actions.fetchNoPicById(id, true);
        const logicList = await Survey.Actions.fetchLogicList(id, data);
        setLogicList(logicList);
        setStepData(false);
      } else if (step === 2) {
        setStepData(true);
        // survey = await Survey.Actions.fetchNoPicById(id, true);
        const collectionList = await Survey.Actions.fetchCollectionList(id);
        setCollectionList(collectionList);
        setStepData(false);
      } else if (step === 3) {
        setStepData(true);
        survey = await Survey.Actions.fetchNoPicById(id, true);
        setData(survey);
        extraSurveyData.title = (survey && survey.title) || "";
        setExtraSurveyData(extraSurveyData);
        const statisticsData = await Survey.Actions.fetchStatisticsData(
          id,
          signal
        );
        setStatisticsData(statisticsData);
        setStepData(false);
      }
      // setData(survey);
    } catch (ex) {
      setLoading(false);
      //abortCtrol.signal.aborted = true 中斷fetch
      if (abortCtrol.signal.aborted) {
        //console.log("error info ", abortCtrol);
      } else {
        console.debug("各路工程師想要使用者回老家");
        navigate("/");
      }
      throw ex;
    }

    setLoading(false);
  }

  // 查詢鎖定狀態
  async function fetchLock() {
    const id = queryString.parse(props.location.search).id;
    let res = await Survey.Actions.fetchLock(id);
    if (res && res.code === "200") {
      const { data } = res;
      // setLockInfo(res.data);

      var lockUserName = "";
      setCanEdit(null);
      data.forEach(function(item, i) {
        if (lockUserName !== "") {
          lockUserName += ", ";
        }
        lockUserName += item.EditLockUserName;
        if (item.EditLockUserId === profile.userId) {
          setCanEdit(true);
        }
      });
      setEditLockUserName(lockUserName !== "" ? lockUserName : null);
      // setCanEdit(data?.CanEdit ? data.CanEdit : null);
      editTimeLimit.current = defaultEditTimeLimit;
      // countDown();
    } else {
      setEditLockUserName(null);
      setCanEdit(null);
    }
  }

  // 登出鎖定狀態
  async function fetchUnlock() {
    const id = queryString.parse(props.location.search).id;
    await Survey.Actions.fetchUnlock(id);
    // console.log("SurveyDetailPage - useEffect - leave");
  }

  // 倒數計時的 Function
  function countDown() {
    // 有編輯權限，則才會進行設定計時器
    if (canEdit) {
      interval = setInterval(() => {
        // console.log('defaultEditTimeLimit', defaultEditTimeLimit)
        // console.log("editTimeLimit", editTimeLimit);
        const { current } = editTimeLimit;
        if (current > 0) {
          editTimeLimit.current = current - 1;
        } else {
          fetchUnlock(); //登出
          setEditLockUserName(null);
          setCanEdit(null);
          // 逾時登出提醒
          // Ant.message.warning("閒置過久，自動釋放鎖定狀態", 0);

          setAlertMessage({
            title: "問卷狀態更改",
            text: `因畫面閒置超過${defaultEditTimeLimit /
              60}分鐘，自動釋放鎖定狀態，提供其他團隊成員編輯問卷`,
            btnText: "確定",
          });
          setIsAlert(true);
          // 釋放倒數計時器
          clearInterval(interval);
        }
      }, 1000);
    }
  }

  // 查詢編輯狀態，將條件設定為定時查詢
  // 每30秒查詢鎖定狀態
  function fetchTimer() {
    timerInterval = setInterval(() => {
      fetchLock();
    }, 300000);
  }

  /**
   * 點擊【下一步】按鈕，執行的功能
   * @param {*} nextStep 0~3
   */
  function setStep(nextStep) {
    if (nextStep !== step) {
      _setStep(nextStep);
      setEditingIdx(null);
    }
  }

  //全域tab 2~4 loading icon
  // if (stepData) {
  //   return (
  //     <Wrapper
  //       css={
  //         "height: 100vh; display: flex; align-items: center;justify-content: center;"
  //       }
  //     >
  //       <Ant.Spin />
  //     </Wrapper>
  //   );
  // }

  if (!data && loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: "999999",
        }}
      >
        <Ant.Spin />
      </div>
    );
  }

  const questions = data?.questions || [];

  // 至少有一題必選才可以到第二步驟
  const isStep0Disabled =
    !data?.audit &&
    step === 0 &&
    !questions.some(o => "required" in o?._data && o?._data?.required === true);

  return (
    // 編輯頁面架構
    <Wrapper
      style={{
        position: "relative",
        overflow: isSticky ? "scroll" : "hidden",
        width: "100vw",
        height: "100vh",
      }}
    >
      <HeadNavBar>
        <MainNavbar>
          <div className="logo">
            <Link
              to="/"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src="/images/Survey_Logo_Color_v2.svg" alt="" />
            </Link>
          </div>
          <Font.LargeTitle className="title">
            {data?.title || extraSurveyData?.title || ""}
            {data?.audit && (
              <Font.Head className="sub-title">版本凍結中</Font.Head>
            )}
            {editLockUserName && (
              <>
                {editLockUserName ? (
                  <Ant.Tooltip
                    title={`${editLockUserName} 正在編輯此問卷內容，如果要編輯此內容，先請 ${editLockUserName} 退出視窗。`}
                  >
                    <SvgIcon.Lock
                      style={{
                        margin: "0 2px 0 18px",
                        display: "flex",
                      }}
                      size={24}
                      color={Color.MPurple_6}
                    />
                  </Ant.Tooltip>
                ) : (
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      margin: "0 18px",
                      backgroundColor: "black",
                    }}
                  ></div>
                )}
                {editLockUserName ? (
                  <>
                    <Font.Body
                      style={{ color: Color.MPurple_6, marginRight: 7 }}
                    >
                      {editLockUserName}
                    </Font.Body>
                    <Font.Body>正在編輯中</Font.Body>
                  </>
                ) : null}
              </>
            )}
          </Font.LargeTitle>
          <div className="left-items-wrapper">
            <Ant.Button
              ghost
              icon={
                <Icon.RemoveRedEye
                  color={"#534ab3"}
                  size={FontSize.head}
                  style={{ marginRight: 8 }}
                />
              }
              style={{
                color: "#534ab3",
                marginRight: 24,
                border: "1px solid rgba(83, 74, 179, 0.85)",
                maxWidth: 78,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={async () => {
                setEditingIdx(null);
                window.open(`/preview/?id=${data.id}`);
              }}
            >
              {"預覽"}
            </Ant.Button>
            <div
              className="help-icon"
              onClick={() => {
                navigate("/help");
              }}
            >
              <Icon.QuestionCircleFilled
                size={32}
                style={{ color: "#423a8f", fontSize: 32 }}
              />
            </div>
            <div
              style={{
                marginLeft: 20,
                display: "flex",
                alignItems: "center",
                fontSize: `${FontSize.head}px`,
              }}
            >
              你好！ {profile?.userName || ""}
              <Ant.Popover
                placement="bottomRight"
                content={
                  <div
                    style={{ minWidth: 122, marginLeft: -16, marginRight: -16 }}
                  >
                    {profile?.RoleId === 1 && (
                      <div
                        className="hover-mask"
                        style={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          paddingLeft: 16,
                          paddingRight: 16,
                        }}
                        onClick={() => {
                          navigate("/users");
                        }}
                      >
                        帳號列表
                      </div>
                    )}
                    <div
                      className="hover-mask"
                      style={{
                        paddingTop: 4,
                        paddingBottom: 4,
                        paddingLeft: 16,
                        paddingRight: 16,
                      }}
                      onClick={() => {
                        props.appActions.logout();
                        // appActions.logout();
                        // navigate("/");
                        console.debug("各路工程師想要使用者回老家");
                        window.location.replace("/");
                      }}
                    >
                      登出
                    </div>
                  </div>
                }
                trigger="hover"
              >
                <div className="account-icon" style={{ marginLeft: 12 }}>
                  <Icon.AccountCircle color={"#423a8f"} size={48} />
                </div>
              </Ant.Popover>
            </div>
          </div>
        </MainNavbar>
        <SubNavbar disable={editingIdx !== null}>
          <div className="steps-wrapper">
            <Ant.Steps current={step} size="small">
              {Object.keys(STEP)
                .sort((a, b) => STEP[a].key - STEP[b].key)
                .map(k => STEP[k])
                .map((s, idx) => (
                  <Ant.Steps.Step
                    key={idx}
                    icon={
                      <NumberCircle selected={s.key === step}>
                        <div className="step-number">{idx + 1}</div>
                      </NumberCircle>
                    }
                    title={s.display}
                    description=""
                  />
                ))}
            </Ant.Steps>
          </div>

          <div className="action-buttons">
            {// logicList 產生時，已經有判斷此 logic，並將結果放置 needAdjust 中
            // 顯示提示只需取用即可
            (logicList.length === 0 ? (
              false
            ) : (
              logicList.filter(x => x._data.needAdjust === true).length > 0
            )) ? (
              <Ant.Tooltip title="需修復『邏輯規則』" color={"#fff"}>
                <Ant.Button type="primary" icon={<InfoCircleOutlined />} danger>
                  設計提醒
                </Ant.Button>
              </Ant.Tooltip>
            ) : null}
            <div className="save-hint">
              {data && data.upDateTime
                ? "儲存於 " + moment(data.upDateTime).format("HH:mm A")
                : null}
            </div>
            {step !== 0 && (
              <Ant.Button
                style={{
                  minWidth: 74,
                  color:
                    step !== 3 ? "rgba(83, 74, 179, 0.85)" : Color.GreyWhite,
                  backgroundColor:
                    step !== 3 ? "rgba(255, 255, 255, 0.4)" : Color.Purple,
                  border: "1px solid rgba(83, 74, 179, 0.85)",
                  marginRight: step !== 3 ? 8 : 0,
                }}
                onClick={() => setStep(step - 1)}
              >
                上一步
              </Ant.Button>
            )}

            {step !== 3 && (
              <Ant.Button
                style={{
                  minWidth: 112,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: Color.GreyWhite,
                  backgroundColor: Color.Purple,
                }}
                // disabled={step === 0 && !isStep0Valid}
                onClick={() => {
                  if (isStep0Disabled) {
                    Ant.Modal.warning({
                      title: "邏輯錯誤",
                      content: "至少一題必選",
                      okText: "關閉",
                      onOk: () => {
                        window.opener = null;
                        window.open("", "_self");
                        window.close();
                      },
                      icon: (
                        <ExclamationCircleFilled
                          color={Color.LightGold}
                          size={20}
                        />
                      ),
                    });
                  } else {
                    setStep(step === 3 ? step : step + 1);
                  }
                }}
              >
                下一步
              </Ant.Button>
            )}
          </div>
        </SubNavbar>
      </HeadNavBar>
      {step !== 3 && (
        <div className="container">
          <div className="side-menu">
            {(((!stepData || data) && !loading) || step === 0) && (
              <SideMenu
                step={step}
                survey={data}
                setSurvey={setData}
                unModelSurvey={unModelSurvey}
                updater={updater}
                setUpdater={setUpdater}
                editingIdx={editingIdx}
                setEditingIdx={setEditingIdx}
                editingMode={editingMode}
                setEditingMode={setEditingMode}
                previewMode={previewMode}
                logicList={logicList}
                setLogicList={setLogicList}
                collectionList={collectionList}
                setIsCreateNewPage={setIsCreateNewPage}
                isCreateNewPage={isCreateNewPage}
                canEdit={canEdit ? canEdit : false}
              />
            )}
          </div>
          <div id="mian" className="content-outside scrollbar-big">
            {(stepData || loading) && !data && loading && (
              <div
                style={{
                  height: "100vh",
                  width: "100vw",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "fixed",
                  zIndex: "999999",
                }}
              >
                <Ant.Spin />
              </div>
            )}
            {(((!stepData || data) && !loading) || step === 0) && (
              <Content
                key={updater}
                step={step}
                survey={data}
                extraSurveyData={extraSurveyData}
                loading={loading}
                setSurvey={setData}
                updater={updater}
                setUpdater={setUpdater}
                editingIdx={editingIdx}
                setEditingIdx={setEditingIdx}
                editingMode={editingMode}
                setEditingMode={setEditingMode}
                previewMode={previewMode}
                logicList={logicList}
                setLogicList={setLogicList}
                collectionList={collectionList}
                setCollectionList={setCollectionList}
                setIsCreateNewPage={setIsCreateNewPage}
                isCreateNewPage={isCreateNewPage}
                canEdit={canEdit ? canEdit : false}
              />
            )}
          </div>
        </div>
      )}

      {step === 3 && (
        <>
          {(!data || loading) && (
            <div
              style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                zIndex: "999999",
              }}
            >
              <Ant.Spin />
            </div>
          )}
          {data && !loading && (
            <div className="container flex-grow">
              <SurveyStat
                {...props}
                survey={data}
                loading={stepData}
                statisticsData={statisticsData}
                backToCollectStep={() => _setStep(0)}
                setIsSticky={setIsSticky}
              />
            </div>
          )}
        </>
      )}
      {isAlert && ( //TODO: Hanford needs to write onClick function in AlertWindow
        <AlertWindow
          title={alertMessage.title}
          text={alertMessage.text}
          btnText={alertMessage.btnText}
          setAlertBtnAction={() => {
            window.location.reload();
            setIsAlert(false);
          }}
          isOpen={isAlert}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #eff1f7;

  & .container {
    display: flex;
    & .side-menu {
      width: 280px;
      height: calc(
        100vh - ${configs.mainNavbarHeight}px - ${configs.subNavbarHeight}px
      );
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
      position: relative;
      left: 0;
      z-index: 4;
      overflow: hidden;
      overflow-y: auto;
    }
    /* 側邊攔scrollbar */
    & .content {
      flex: 1;
    }
    & .content-outside {
      flex: 1;
      height: calc(
        100vh - ${configs.mainNavbarHeight}px - ${configs.subNavbarHeight}px
      );
      position: relative;
      left: 0;
      z-index: 1;
      overflow: auto;
    }
  }

  & .container.flex-grow {
    height: calc(
      100vh - ${configs.mainNavbarHeight}px - ${configs.subNavbarHeight}px
    );
  }

  ${props => props.css}
`;

const HeadNavBar = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: auto;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.16);
`;

const MainNavbar = styled.div`
  height: ${configs.mainNavbarHeight}px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(187, 187, 203, 0.85);
  background-color: #fdfcfc;
  padding-right: 34px;

  .logo {
    width: 132px;
    height: 66px;
    color: #fff;
    /* background-color: ${Color.Purple}; */
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      width: 96px;
      height: 100%;
      padding-left: 16px;
      padding-right: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  & .title {
    flex: 1;
    padding-left: 42px;
    display: flex;
    align-items: center;
  }

  & .sub-title {
    padding-left: 8px;
    color: ${Color.Red_6};
  }

  & .left-items-wrapper {
    display: flex;
    align-items: center;

    & .help-icon {
      display: flex;
      align-items: center;
    }
  }
`;

const SubNavbar = styled.div`
  height: ${configs.subNavbarHeight}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fdfcfc;
  padding-left: 60px;
  padding-right: 32px;
  border-bottom: 1px solid rgba(187, 187, 203, 0.85);

  ${props =>
    props.disable && "cursor: not-allowed; & > * { pointer-events: none; }"}

  & .steps-wrapper {
    flex-basis: 800px;
  }

  & .action-buttons {
    display: flex;
    flex-basis: 465px;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;

    & .save-hint {
      margin-right: 18px;
      margin-left: 10px;
    }
  }

  /* overwrite ant style */
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #534ab3;

    ::after {
      background-color: #534ab3;
    }
  }

  .ant-steps-item-wait {
    > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      ::after {
        background-color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  .ant-steps-item-finish {
    > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      ::after {
        background-color: rgba(0, 0, 0, 0.65);
      }
    }
  }
`;

const NumberCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${props => (props.selected ? "#534ab3" : "rgba(0, 0, 0, 0.65)")};
  background-color: ${props => (props.selected ? "#534ab3" : "#ffffff")};

  & .step-number {
    font-size: ${FontSize.body}px;
    color: ${props => (props.selected ? "#ffffff" : "rgba(0, 0, 0, 0.65)")};
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(SurveyDetailPage)
);
