import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as Ant from "antd";
import Widget, { Font, WarnLabel, Color } from "./Widget";
import CollectionList from "./CollectionList";
import CollectionForm from "./CollectionForm";
import { ExclamationCircleFilled } from "@ant-design/icons";
import * as Survey from "../Contexts/SurveyContext";

function SurveyDetailContentCollect(props) {
  const { collectionList, editingIdx, setEditingIdx } = props;
  const selectedCollection = collectionList[editingIdx] || null;
  const [isOnClick, setIsOnClock] = useState(false);
  useEffect(() => {
    setIsOnClock(props.survey.audit || !props.canEdit);
  }, [props]);

  function _confirmCollectionSend() {
    setIsOnClock(true);
    Ant.Modal.confirm({
      title: "確認執行 CRM 陳核流程嗎？",
      content: (
        <div>
          1. 執行陳核流程後，就不能再編輯，包含設計問卷、邏輯規則、收集方式。
          <br />
          2. 須返回 CRM ，到此問卷行銷活動方式，點選【陳核流程】。
        </div>
      ),
      okText: "確認執行",
      okType: "ghost",
      okButtonProps: {
        style: {
          color: Color.Purple,
          border: `1px solid ${Color.Purple}`,
        },
      },
      onOk: async () => {
        await Survey.Actions.surveySignOff(props.survey);
        let survey = await Survey.Actions.fetchById(props.survey.id, true);
        props.setSurvey(survey.survey);
        props.setUpdater(props.updater + 1);
        return;
      },
      cancelButtonProps: {
        type: "primary",
      },
      cancelText: "讓我再想想",
      onCancel: () => {
        setIsOnClock(false);
        return 0;
      },
      icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
    });
  }

  if (!collectionList) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: "999999",
        }}
      >
        <Ant.Spin />
      </div>
    );
  }
  // 選中收集方式，呈現收集表單
  if (selectedCollection) {
    return (
      <Wrapper>
        <div className="content" style={{ backgroundColor: "transparent" }}>
          <CollectionForm
            {...props}
            collection={selectedCollection}
            backToList={() => setEditingIdx(null)}
          />
        </div>
      </Wrapper>
    );
  } else if (!collectionList) {
    // 沒有收集方式陣列，載入中
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: "999999",
        }}
      >
        <Ant.Spin />
      </div>
    );
  } else if (collectionList && collectionList.length === 0) {
    // 沒有收集方式
    return (
      <Wrapper>
        <div className="content" style={{ backgroundColor: "transparent" }}>
          <Font.LargeTitle style={{ marginBottom: 20 }}>
            收集方式列表
          </Font.LargeTitle>
          <Widget.FlexCenter style={{ marginTop: 66 }}>
            <Ant.Empty />
            <Font.Title
              style={{
                margin: "30px 10px",
                textAlign: "center",
                color: Color.GreyBlack_45,
              }}
            >
              尚未建立任何收集方式
              <br />
              請在左邊工具列點選所需的收集方式
            </Font.Title>
          </Widget.FlexCenter>
        </div>
      </Wrapper>
    );
  } else if (collectionList) {
    // 呈現收集方式列表
    return (
      <Wrapper>
        <div className="content" style={{ backgroundColor: "transparent" }}>
          <Font.LargeTitle style={{ marginBottom: 20 }}>
            收集方式列表
          </Font.LargeTitle>
          <CollectionList {...props} collections={collectionList} />
          <Ant.Button
            disabled={props.survey.audit || !props.canEdit}
            type="primary"
            style={
              isOnClick
                ? {
                    marginTop: 93,
                    backgroundColor: Color.Black_15,
                    borderColor: Color.Black_15,
                    color: Color.Black_10,
                  }
                : {
                    marginTop: 93,
                    backgroundColor: Color.ChtBlue_5,
                    borderColor: Color.ChtBlue_5,
                  }
            }
            onClick={_confirmCollectionSend}
          >
            我了解簽核流程，前往 CRM 執行陳核
          </Ant.Button>
          <WarnLabel style={{ margin: "10px 0px" }}>
            執行陳核流程後，就不能再編輯問卷，包含設計問卷、邏輯規則、收集方式。
          </WarnLabel>
          <Widget.Line />
          <Widget.FlexCol>
            <Font.Body
              style={{ color: Color.Purple, marginBottom: 8, marginTop: 20 }}
            >
              返回 CRM 簽核流程說明
            </Font.Body>
            <Widget.FlexRow
              style={{
                border: `1px solid ${Color.LightPurple_45}`,
                padding: "16px 20px",
                backgroundColor: "#f6f5ff",
              }}
            >
              <AntStepCssOverwriteWrapper>
                <Ant.Steps current={-1}>
                  <Ant.Steps.Step
                    status={"process"}
                    title="執行 CRM 【陳核流程】"
                    description="返回 CRM 問卷行銷活動方式，點選【陳核流程】，進行活動陳核，等待主管決行。"
                  />
                  <Ant.Steps.Step
                    status={"process"}
                    title="點擊 CRM【發佈行銷方式】"
                    description="主管決行通過後，一定要手動至 CRM 點擊【發佈行銷方式】，然後等待問卷排程時間。"
                  />
                  <Ant.Steps.Step
                    status={"process"}
                    title="自動排程公開問卷"
                    description="一旦到有效開始時間，系統則立即公開正式網址，直到活動時間結束。"
                  />
                </Ant.Steps>
              </AntStepCssOverwriteWrapper>
            </Widget.FlexRow>
          </Widget.FlexCol>
        </div>
      </Wrapper>
    );
  }
}

const AntStepCssOverwriteWrapper = styled.div`
  flex: 1;
  &
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: ${Color.Purple};
    opacity: 0.3;
  }

  &
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    max-width: 200px;
  }
  & .ant-steps-item-process .ant-steps-item-icon {
    background: transparent;
    border: 1px solid ${Color.GreyBlack};

    & > .ant-steps-icon {
      color: ${Color.GreyBlack};
    }
  }
`;

const Wrapper = styled.div`
  padding: 20px 20px;

  .banner {
    width: 133px;
    height: 160px;
    background-color: #ccc;
  }

  .content {
    max-width: 1024px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
  }
`;

export default SurveyDetailContentCollect;
